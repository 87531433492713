<template>
  <div class="pop_container">
    <div class="title_area">Job informations in the uploaded file</div>
    <div class="body_area">
      <div class="form_section_generate_slip">
    
        <table class="change_pass_table">
          <tr>
          <p style="color: red;text-align: center;">{{ showCount.success_count}} jobs are matched, and {{ showCount.error_count}} jobs failed</p>
          </tr>
        </table>
        <template v-if="showCount.failed_upload.length || showCount.update_upload.length ||  showCount.success_upload.length">
          <hr>
          <center>
            <h3 v-if="'failed' == filter">Failed Uploads</h3>
            <h3 v-else-if="'success' == filter">Success Uploads</h3>
            <h3 v-else-if="'update' == filter">Update Confirmation</h3>
            <h3 v-else-if="'selected' == filter">Selected Uploads</h3>
          </center>
          <br>
          <div>
            <input type="text" placeholder="Search..." v-model="searchText">
          </div>
          <br>
          <table class="filter tab-open">
            <thead>
              <tr>
                <th @click="selectFilter('failed')" :class="'failed' == filter ? `selected` : '' ">FAILED</th>
                <th @click="selectFilter('success')" :class="'success' == filter ? `selected` : '' ">SUCCESS</th>
                <th @click="selectFilter('update')" :class="'update' == filter ? `selected` : '' ">DUPLICATE</th>
                <th @click="selectFilter('selected')" :class="'selected' == filter ? `selected` : '' ">SELECTED</th>
              </tr>
            </thead>
          </table>
          <br>
          <table class="failed-upload-table" >
            <thead>
              <tr>
                <td>Booking Ref</td>
                <td>Amount</td>
                <td>Duplicate</td>
                <td v-if="'success' != filter">Re Upload Data</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="failedUpload in failedUploads">
                <td>{{ failedUpload.booking_ref }}</td>
                <td>{{ failedUpload.amount }}</td>
                <td>{{ failedUpload.duplicate ? 'Y':'N' }}</td>
                <td v-if="'success' != filter">
                  <input type="checkbox" @click="toggleUpload(failedUpload)" :checked="checkReuploadData(failedUpload)">
                </td>
              </tr>
              <tr v-if="!failedUploads.length">
                <td colspan="4">No Data</td>
              </tr>
            </tbody>
          </table>
        </template>
        <div class="pop_buttons_container" style="margin-top: 37px;">
          <a class="tr_cancel" @click="saveUploadedFiles" style="margin-right: 6px;">Proceed</a>
          <a class="tr_cancel" @click="closeModal" style="margin-right: 6px;">Close</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
export default {
  name: "popInvoiceUploadList",
  data(){
    return {
      searchText:'',
      reuploadData:[],
      loader:false,
      api_url:'',
      filter:'failed',
    }
  },
  computed:{
    failedUploads() {
      if ('failed' == this.filter) {
        return this.showCount.failed_upload.filter((uploadData) => {
          return this.searchText.toLowerCase().split(' ').every(v => uploadData.booking_ref.toLowerCase().includes(v))
        })
      } else if ('success' == this.filter) {
        return this.showCount.success_upload.filter((uploadData) => {
          return this.searchText.toLowerCase().split(' ').every(v => uploadData.booking_ref.toLowerCase().includes(v))
        })
      } else if ('update' == this.filter) {
        return this.showCount.update_upload.filter((uploadData) => {
          return this.searchText.toLowerCase().split(' ').every(v => uploadData.booking_ref.toLowerCase().includes(v))
        })
      } else if ('selected' == this.filter) {
        return this.reuploadData.filter((uploadData) => {
          return this.searchText.toLowerCase().split(' ').every(v => uploadData.booking_ref.toLowerCase().includes(v))
        })
      }
    }
  },
  created() {
    this.api_url = process.env.VUE_APP_API_URL;
  },
  methods: {
    selectFilter(filter) { 
      this.filter = filter
    },
    async saveUploadedFiles() {
      const url = `${this.api_url}/api/invoiceupload/reupload`
      if (this.loader) {
        return
      }
      if (!this.reuploadData.length) {
        this.$emit("closeModal");
        return
      }

      try {
        this.loader = true
        const response = await this.axios.post(url, this.reuploadData)
        this.loader = false
        this.toast.success(response.data.msg);
        this.$emit("closeModal");
      } catch(e) {
        this.toast.success("Failed reuploading");
        this.loader = false
      }

    },
    closeModal() {
       this.$emit("closeModal");
    },
    toggleUpload(failedData) {
      if (this.checkReuploadData(failedData)) {
        let location = -1
         _.forEach(this.reuploadData, function (v, i) {
          if(v.booking_ref == failedData.booking_ref) {
            location = i
          }
        })
        if (location === -1) {
          return
        }

        this.reuploadData.splice(location, 1)
        return
      }
      this.reuploadData.push(failedData)
    },
    checkReuploadData(failedData) {
      let exist = false
      _.forEach(this.reuploadData, function (v, i) {
        if(v.booking_ref == failedData.booking_ref) {
          exist = true
          return false
        }
      })

      return exist
    }
  },
  props: ["errors","showCount"]
};
</script>
<style lang="scss">
.form_section_generate_slip {
  padding:20px;
  max-height: 500px;
  overflow: auto;
}
.form_section_generate_slip table.change_pass_table {
  width: 400px;
  margin: auto;
  td {
    text-align: left;
    padding: 0;
    label {
      padding: 0;
      height: auto;
    }
  }
}
.failed-upload-table {
  thead {
    background-color: #06A5ED;
    tr {
      background-color: #06A5ED;
      td {
        background-color: #06A5ED;
        color:white;
        font-weight: bold;
      }
    }
  }
  tbody {
    overflow-x: scroll;
    tr:hover{
      background-color: #dfdfdf;
    }
    tr {
      td {
        border: 1px solid black;
        color:black;
      }
    }
  }
}
.filter.tab-open {
  thead {
    tr {
      cursor: pointer;
      th {
        background-color: #cfcfcf;
      }
      th.selected {
        background-color: black !important;
        color: white;
      }
    }
  }
} 
</style>